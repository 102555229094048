<template>
    <div>  
      <FormulaBanner :title="title" :formulaCode="formulaCode"/>
      <div class="index">
        <!-- 表单 -->
        <div>
          <div class="top_nav"><span></span>{{ title }}</div>
          <div class="formBody">
            <!-- 表单 -->
            <van-form @submit="onSubmit">
              <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
              <van-field
                v-model="tizhong"
                :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
              >
                <template #input>
                  <div class="titles">
                   <span class="titlesName">体重 <i>*</i></span>
                   <span style="display: flex;align-items: center;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field v-model="tizhong" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"> <span>kg</span></div>
                   </span>
                  </div>
                </template>
              </van-field>
              <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
                <van-button class="formula-template-button"  block type="info" native-type="submit" style="width:90px">提交</van-button>
              </div>
            </van-form>
            <!-- 计算结果 -->
            <!-- <div class="CalculationResultsBox" v-if="Submitted">
              <div class="CalculationResults"><span>计算结果</span></div>
              <div>
                <div class="CalculationDetail"><span></span>每小时液体维持量：{{ this.meixiaoshiyeti }} ml/小时</div>
              </div>
              <div>
                <div class="CalculationDetail"><span></span>每天液体维持量：{{ this.meitianyeti }} ml/24小时</div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- 计算结果 -->
        <CalculationResults v-if="show" :countText="countText" :countTextList="countTextList"></CalculationResults>
        <!-- 后台设置的值 -->
        <FormulaExplain :explain-data="StandardDescription"></FormulaExplain>
        <!-- 参考文献 -->
        <FormulaReferences :references-data="references"></FormulaReferences>
      </div>
    </div>
  
  </template>
  
  <script>
  import {Toast} from "vant";
  import { getFormula, postFormula,getFormulaHuiXian} from "@/service/BodyMassIndex_api";
  import MyPatient from '@/components/MyPatient.vue'
  import FormulaBanner from '@/components/FormulaBanner.vue'
  import FormulaReferences from '../../../components/template1/FormulaReferences.vue';
  import FormulaExplain from '@/components/template1/FormulaExplain'
  import CalculationResults from "@/components/CalculationResults";
  import router from "@/router";
  export default {
    name: "medicalSearch",
    data(){
      return{
        compute:true,
        show:false,
      countTextList:[],
      countText:'',
      //   searchValue:null,
      meixiaoshiyeti:'',
      Submitted:false,
      columnCode:'',
      formulaId:'',
      formulaCode:'',
      title:"",
      memberId: '1111',
      patientId:null,
      selectValue: '',
      tizhong:"",
      showPopover: false,
      StandardDescription:[],
      BodyMassIndex:"",
      references:[],
      zhongguo:"",
      guoji:"",
      meitianyeti:"",
      }
    },
    components: {
      FormulaBanner,
      MyPatient,
      FormulaReferences,
      FormulaExplain,
      CalculationResults
    },
    created(){
      this.getTheFormula()
      this.showRecord()
    },
    watch:{
      "tizhong":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },

    },

    methods:{
      // 回显数据
      async showRecord(){
        if(this.$router.currentRoute.query.location == 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId){
          
          const data = {
              id:this.$router.currentRoute.query.logId
            }
            const response = await getFormulaHuiXian(data)
          if (response.code === 0) {  
            // console.log("回显数据",response.data)
            this.tizhong = response.data.content.tizhong.value
            if (response.data.patientId) this.selectValue = {id: response.data.patientId, name: response.data.patientName};this.patientId = response.data.patientId
            // 计算结果
            this.Submitted = true
            this.meixiaoshiyeti = response.data.result.meixiaoshiyetiweichiliang.value
            this.meitianyeti = response.data.result.meitianyetiweichiliang.value

            var data1 =  `每小时液体维持量：${ this.meixiaoshiyeti } ml/h`
            var data2 =  `每天液体维持量：${ this.meitianyeti } ml/24h`
            this.countTextList.push(data1,data2) 
            // console.log("id",this.memberId)
          } else {
            Toast.fail(response.message)
          }
          this.$nextTick(() => {
            this.show=true
          })
        }
      },
      // 获取公式
      async getTheFormula(){
        // console.log("路由",router)
        var data = router.currentRoute.path.substring(9,router.currentRoute.path.Length)
        const response = await getFormula(this.$router.currentRoute.query.channel,data)
        if (response.code === 0) {  
          this.StandardDescription = response.data.explain // 存储标准说明
          this.references = response.data.references // 参考文献 
          // console.log("公式数据",response.data)
          this.title = response.data.chineseName //公式名称
          this.formulaId = response.data.id
          this.columnCode = response.data.columnCode
          this.formulaCode = response.data.code
        } else {
          Toast.fail(response.message)
        }
      },
      async onSubmit(){
        // 计算结果 每小时液体维持量
        if( 1<=this.tizhong && this.tizhong<=10){
          this.meixiaoshiyeti = this.tizhong * 4
        }else if(11<=this.tizhong && this.tizhong<=20){
          this.meixiaoshiyeti = 40+(2*(this.tizhong - 10))
        }else if(20<this.tizhong ){
          this.meixiaoshiyeti = 60+(this.tizhong - 20)
        }
        // 每天
        if( 1<=this.tizhong && this.tizhong<=10){
          this.meitianyeti = this.tizhong * 100
        }else if(11<=this.tizhong && this.tizhong<=20){
          this.meitianyeti = 1000 + (50 * (this.tizhong - 10))
        }else if(20<this.tizhong ){
          this.meitianyeti = 1500 + (20 * (this.tizhong - 20))
        }
        // console.log("计算结果",this.meitianyeti)
        this.countTextList = []
        var data1 =  `每小时液体维持量：${ this.meixiaoshiyeti } ml/小时`
        var data2 =  `每天液体维持量：${ this.meitianyeti } ml/24小时`
        this.countTextList.push(data1,data2) 
        this.Submitted = true
        var data = {}
        if(this.$router.currentRoute.query.location == 'YXGS_RECORD_LIST'){
          data = {
            columnCode:this.columnCode,
            patientId:this.patientId,
            formulaId:this.formulaId,
            formulaCode:this.formulaCode,
            content:{ 
              tizhong:{value:this.tizhong , unit:"kg"},
            },
            result:{
              meixiaoshiyetiweichiliang:{value:this.meixiaoshiyeti,unit:"ml/小时"},
              meitianyetiweichiliang:{value:this.meitianyeti,unit:"ml/24小时"},
              DisplayResults:`每小时液体维持量：${ this.meixiaoshiyeti } ml/小时；每天液体维持量：${ this.meitianyeti } ml/24小时`
            },
            channel:this.$route.query.channel,
            location:this.$route.query.location,
            id:this.$router.currentRoute.query.logId
          }
        }else{
          data = {
            columnCode:this.columnCode,
            patientId:this.patientId,
            formulaId:this.formulaId,
            formulaCode:this.formulaCode,
            content:{ 
              tizhong:{value:this.tizhong , unit:"kg"},
            },
            result:{
              meixiaoshiyetiweichiliang:{value:this.meixiaoshiyeti,unit:"ml/小时"},
              meitianyetiweichiliang:{value:this.meitianyeti,unit:"ml/24小时"},
              DisplayResults:`每小时液体维持量：${ this.meixiaoshiyeti } ml/小时；每天液体维持量：${ this.meitianyeti } ml/24小时`
            },
            channel:this.$route.query.channel,
            location:this.$route.query.location,
          } 
        }
        // console.log("参数",data)
        // 连接口
        const response = await postFormula(data)
        if (response.code === 0) {  
          Toast.success('提交成功')
          this.show=true
        } else {
          Toast.fail(response.message)
        }
      },
      userChange (data) {
        this.selectValue = data
        // console.log("全部数据",this.selectValue)
        this.patientId = this.selectValue.id
      },
      // 开参考文献链接
      openLink(u){
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      }
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  ::v-deep .result2Style{
    justify-content: left !important;
  }
  .index{
    background: #F6F6F6;
    padding: 10px 15px;
    height: 100vh;
    // overflow:scroll ;
    .StandardDescription{
      margin-top:10px;
      .top_nav{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px; 
          span {
            display: inline-block;
            width: 5px;
            height: 15px;
            background: #259beb;
            margin-right: 5px;
          }
      }
      .StandardDeatil{
        width:100%;
        padding: 15px 10px;
      //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
        .references{
          margin-bottom:15px
        }
      }
      .StandardDeatil2{
        width:100%;
        padding: 15px 10px;
        padding-bottom:1px;
      //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
        .references{
          margin-bottom:15px
        }
      }
    }
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .formBody{
      background-color: #fff;
      padding-bottom:10px;
      .CalculationResultsBox{
          padding:0 8px;
        .CalculationResults{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: center;
          margin-bottom:10px
        }
        .CalculationDetail{
          font-size: 15px;
          color: #333333;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom:10px;
          background-color: #f6f6f6;
          // height: 16px;
          span{
            display: inline-block;
            width: 3px;
            height: 24px;
            background: #fe9712;
            margin-right: 5px;
          }
        }
      }
    }
    .titles{
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height: 45px;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
      .titlesName{
        font-size:16px;
        i{
          color: red;
        }
      }
      .information{
        font-size:16px;
        margin-right:4px;
        .PleaseSelect{
          color: #b9b9b9;
        }
        .vanCell{
          width:50px;
          padding:0;
          height: 100%;
        }
      }
      .vBotton{
          width:60px
      }
      .unit{
        height: 45px;
      //   line-height: 45px;
        display: flex;
        align-items: center;
        font-size:16px;
        width:20px;
      }
      .vanIcon{
        font-size:13px;
        color:#a8a8a8;
        font-weight: 650;
      }
    }
  }
  ::v-deep .van-cell {
        padding:0px 0px;
  }
  ::v-deep .van-field__control{
      height: 45px;
      font-size: 16px;
  }
  ::v-deep .van-field__error-message {
      position: absolute;
      top: 25px;
      left: 10px;
  }
  ::v-deep p {
    padding: 0;
    margin: 0;
  }
  </style>
  